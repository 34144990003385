.ksvesity__kidzonetestimony{
    display: flex;
    background: linear-gradient(180deg, #FFFAF3 0%, #FFFAF3 100%);
    ;
}
.ksvesity__kidzonetestimony-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 80px;

}
.ksvesity__kidzonetestimony-container p{
    font-family: Nunito;
    font-size: 24px;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    color: #221638;
    width: 966.2937622070312px;

}
.ksvesity__kidzonetestimony-container h4{
    height: 34px;
    font-family: Nunito;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #0F64D2
}
.ksvesity__kidzonetestimony-container h6{
    height: 22px;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #221638;

}
@media screen and (max-width:1050px) {
    .ksvesity__kidzonetestimony-container p{
        width: 800px;
    }
}
@media screen and (max-width:850px) {
    .ksvesity__kidzonetestimony-container p{
        width: 600px;
    }
}
@media screen and (max-width:650px) {
    .ksvesity__kidzonetestimony-container p{
        width: 400px;
        font-size: 22px;
    }
}
@media screen and (max-width:450px) {
    .ksvesity__kidzonetestimony-container p{
        width: 300px;
        font-size: 19px;
    }
}
