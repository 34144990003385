.ksvesity__cards{
    display: flex;

}
.ksvesity__cards-container{
    display: flex;
    flex-direction: column;
    border: 1px solid #FFE8E3;
    border-radius: 5px;
    padding: 30px;
}
.ksvesity__cards-container p:first-child{
    width: 501.98px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    color: #606060;
}
.ksvesity__cards-container .details{
    display: flex;
    align-items: center;
}
.details div{
    margin-left: 1rem;
    height: 46.39px;
}
.details div h4{
    width: 60px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #0F64D2;
}
.details div p{
    width: 132px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #606060; 
}

@media screen and (max-width:650px) {
    .ksvesity__cards-container p:first-child{
        width: 300px;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 29px;
        color: #606060;
    }
}
@media screen and (max-width:420px) {
    .ksvesity__cards-container p:first-child{
        width: 270px;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 29px;
        color: #606060;
    }
}