body{
    overflow-x: hidden;
}
.logo{
  width: 56px;
  border-radius: 5px;
}
.ksvesity__navbar{
    display: flex;
    height: 85px;
    border-bottom: 1px solid #E4E4E4;
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: white;
}
.ksvesity__navbar-container{
    display: flex;
    width: 100%;
    max-width: 1509px;
    margin: 0 auto;
    padding: 0 50px;
    justify-content: space-between;
    align-items: center;
}
.ksvesity__navbar-container_content-container{
    display: flex;
    transition: 0.3s ease-in-out all;
    scroll-behavior: smooth;

}
.ksvesity__navbar-container_content-container li{
    margin-right: 3rem;
    list-style: none;
}
.ksvesity__navbar-container_content-container a{
    text-decoration: none;
    color: #000;
}
.ksvesity__navbar-container_contact{
  display: flex;
  justify-content: space-between;
  width: 280px;
}
.ksvesity__navbar-container_contact-signin {
    width: 132px;
    height: 42px;
    background-color: #0F64D2;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
}
.ksvesity__navbar-container_contact-signup {
    width: 132px;
    height: 42px;
    background-color: #000;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
}
.ksvesity__navbar-container_menu{
    display: none;
}


@media screen and (max-width:1000px) {
    .ksvesity__navbar-container_menu{
        display: flex;
    }
    .ksvesity__navbar-container_content-container{
        display: none;
    }
    .ksvesity__navbar-container_contact{
        display: none;
    }
}

@media screen and (max-width:850px) {
    .ksvesity__navbar-container{
        padding: 0 30px;
    }
    .ksvesity__navbar-container_image img{
        height: 60px;
    }
}

.ksvesity__navbar-container_menu-links {
    position: absolute;
    height: 100vh;
    left: 0%;
    top: 100%;
    background: rgb(19, 19, 19);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.ksvesity__navbar-container_menu-links ul{
    list-style: none;
    margin-bottom: 4rem;
    margin-top: -7rem;
}

.ksvesity__navbar-container_menu-links ul li a{
    text-decoration: none;
    color: white;
    font-size: 2.5rem;
}
.ksvesity__navbar-container_menu-links ul li a:hover{
    color: #0F64D2;
}
.olumide__navbar-menu_links ul li{
    margin-bottom: 2rem;
}
.ksvesity__navbar-container_menu-button{
  display: flex;
  flex-direction: column;
  height: 105px;
  justify-content: space-between;
}
.ksvesity__navbar-container_menu-button-login{
    width: 132px;
    height: 42px;
    background-color: #0F64D2;
    border: none;
    color: #fff;
    position: relative;
    cursor: pointer;
    z-index: 3;
}
.ksvesity__navbar-container_menu-button-signup{
    width: 132px;
    height: 42px;
    background-color: #000;
    border: none;
    color: #fff;
    position: relative;
    cursor: pointer;
    z-index: 3;

}
.ksvesity__navbar-container_menu-button-login::before{
    content: '';
    width: 0;
    position: absolute;
    inset: 0;
    background-color: #FE4A55;
    z-index: -1;
    transition: 0.3s ease-in-out ;
}
.ksvesity__navbar-container_menu-button-login:hover::before{
    width: 100%;
}
.ksvesity__navbar-container_menu-button-signup::before{
    content: '';
    width: 0;
    position: absolute;
    inset: 0;
    background-color: #FE4A55;
    z-index: -1;
    transition: 0.3s ease-in-out ;
}
.ksvesity__navbar-container_menu-button-signup:hover::before{
    width: 100%;
}
.olumide__hero-text_button:hover{
    color: white;
}

.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.dropdown {
    position: absolute;
    top: 55%;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    padding: 20px;
    background: linear-gradient(180deg, #FFFAF3 0%, #F9F9F9 29.69%, #F9F9F9 60.42%, #FFFAF3 91.15%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  .menu-item:hover .dropdown {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  
  .menu-item:hover .dropdown .column {
    border: none;
    padding: 0;
  }
  
  .menu-item:hover .dropdown .column__courses {
    color: red;
  }
  
  .menu-item:hover .dropdown .column__courses h2 {
    color: red;
  }
  
  .menu-item:hover .dropdown .column h2 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .menu-item:hover .dropdown .column ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-item:hover .dropdown .column ul li {
    margin-bottom: 0.5rem;
  }
  
  .menu-item:hover .dropdown .column ul li a {
    color: #000;
    text-decoration: none;
  }
  
  
  .column:first-child{
    margin-left: 6.11%;
    margin-top: 11.79%;
    width: 70%;
  }
  .column:nth-child(2){
    width: 50%;
   margin-left: 17.27%;
   margin-top: 18.27%;

  }
  .column:nth-child(3){
    width: 50%;
    margin-left: 0;
    margin-top: 18.27%;
  }
  .column h2{
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    color: #2E425C;

  }
  .column p{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #2E425C;
  }
  .column a{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;



    color: #2E425C;
  }
  .column hr{
    border: 1px solid #2E425C;
  }
  
  .column {
    width: 33.33%;
    vertical-align: top;
    margin-left: 5rem;
  } 
  
  

 @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  