

.ksvesity__faqcourses{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ksvesity__faqcourses-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: 4rem auto 4rem auto;
    border-top: 1px solid grey;

}
.ksvesity__faqcourses-container-div{
    border-bottom: 1px solid grey;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ksvesity__faqcourses-container-div_item-faq-p_active{
    display: block;
}
.ksvesity__faqcourses-container-div_item-faq-p_active span{
    color: blue;
    cursor: pointer;
}
.ksvesity__faqcourses-container h1{
    /* margin-top: -1rem; */
    width: 89px;
    height: 43px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 35.2px;
    line-height: 42px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0F64D2;
    margin-bottom: 3rem;
}

.ksvesity__faqcourses-container-div_item-faq-p{
    display: none;
}
.ksvesity__faqcourses-container-div_item-faq h3{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.16px;
    margin-top: 0.7rem;

}
.ksvesity__faqcourses-container-buttons{
    margin-top: 4rem    ;
}
.ksvesity__faqcourses-container-buttons .apply{
    padding: 15px 38.8125px 16.1875px 37.3906px;
    width: 160.2px;
    height: 51.19px;
    background: #0F64D2;
    box-shadow: 0px 10.4px 20.8px rgba(0, 0, 0, 0.2);
    border-radius: 4.8px;
    border: none;
    cursor: pointer;
    color: white;
}
.ksvesity__faqcourses-container-buttons .download{
    padding: 16px 28.9844px 16px 27.7969px;
    width: 202.78px;
    height: 50px;
    background: #F9F9F9;
    box-shadow: 0px 7.2px 14.4px rgba(85, 133, 124, 0.3);
    border-radius: 4.8px;
    border: none;
    cursor: pointer;
    margin-left: 1rem;
    color: #0f64d2;
}

@media screen and (max-width:850px) {
    .ksvesity__faqcourses-container-div_item-faq h3{
        width: 280px;
    }
    .ksvesity__faqcourses-container-div_item-faq p{
        width: 450.04px
    }
    .ksvesity__faqcourses-container-div{
        width: 500px;
    }
    .ksvesity__faqcourses-container{
        width: 500px;
    }

}
@media screen and (max-width:650px) {
    .ksvesity__faqcourses-container-div_item-faq h3{
        width: 280px;
    }
    .ksvesity__faqcourses-container-div_item-faq p{
        width: 360.04px
    }
    .ksvesity__faqcourses-container-div{
        width: 400px;
    }
    .ksvesity__faqcourses-container{
        width: 400px;
    }
    .ksvesity__faqcourses-container-buttons{
        margin-top: 2rem;
    }
    .ksvesity__faqcourses-container-buttons .apply{
        padding: 15px 38.8125px 16.1875px 37.3906px;
        width: 160.2px;
        height: 51.19px;
        background: #0F64D2;
        box-shadow: 0px 10.4px 20.8px rgba(0, 0, 0, 0.2);
        border-radius: 4.8px;
        border: none;
        cursor: pointer;
        color: white;
    }
    .ksvesity__faqcourses-container-buttons .download{
        padding: 16px 28.9844px 16px 27.7969px;
        width: 202.78px;
        height: 50px;
        background: #F9F9F9;
        box-shadow: 0px 7.2px 14.4px rgba(85, 133, 124, 0.3);
        border-radius: 4.8px;
        border: none;
        font-size: 16px;
    }

}
@media screen and (max-width:415px) {
    .ksvesity__faqcourses-container-div_item-faq h3{
        width: 280px;
        font-size: 15px;
    }
    .ksvesity__faqcourses-container-div_item-faq p{
        width: 340px;
        font-size: 14px;
        
    }
    .ksvesity__faqcourses-container-div{
        width: 370px;
    }
    .ksvesity__faqcourses-container{
        width: 370px;
    }
    .ksvesity__faqcourses-container-buttons{
        display: flex;
        align-items: center;
        margin-top: 3rem;
    }
    .ksvesity__faqcourses-container-buttons .apply{
        padding: 12px 24.8125px 12.1875px 24.3906px;
        width: 120.2px;
        height: 45.19px;
        background: #0F64D2;
        box-shadow: 0px 10.4px 20.8px rgba(0, 0, 0, 0.2);
        border-radius: 4.8px;
        font-size: 12px;
  
        border: none;
        cursor: pointer;
        color: white;
    }
    .ksvesity__faqcourses-container-buttons .download{
        padding: 12px 28.9844px 16px 27.7969px;
        width: 180.78px;
        height: 45px;
        background: #F9F9F9;
        box-shadow: 0px 7.2px 14.4px rgba(85, 133, 124, 0.3);
        border-radius: 4.8px;
        border: none;
        font-size: 12px;
      
    }
}
@media screen and (max-width:393px) {
    .ksvesity__faqcourses-container-div_item-faq h3{
        width: 250px;
        font-size: 13px;
    }
    .ksvesity__faqcourses-container-div_item-faq p{
        width: 270px;
        font-size: 12px;
        
    }
    .ksvesity__faqcourses-container-div{
        width: 320px;
    }
    .ksvesity__faqcourses-container{
        width: 320px;
    }
}
