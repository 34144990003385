
.ksvesity__allcourses{
    display: flex;

}
.ksvesity__allcourses-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.ksvesity__allcourses-container h1{
    margin-top: 3rem;
}
.ksvesity__allcourses-container h5{
    height: 24px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2E425C;
}
.ksvesity__allcourses-container_courses-details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 0px 42.0121px;
    width: 627.83px;
    height: 200.43px;
    background: #FFFFFF;
    box-shadow: 0px 0.420121px 0.840241px rgba(0, 0, 0, 0.1), 0px 1.4004px 4.20121px rgba(0, 0, 0, 0.1);
    border-radius: 4.20121px;
}
.ksvesity__allcourses-container_courses{
    margin-top: 3.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px 20px;
}

.ksvesity__allcourses-container_courses-details .text h2{
    width: 270px;
    height: 21px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 19.6056px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #2E425C;
    margin-top: 1.5rem;
    cursor: pointer;
    z-index: 2;
}
.ksvesity__allcourses-container_courses-details .text h2 a{
    text-decoration: none;
    list-style: none;
    color: black;
}
.ksvesity__allcourses-container_courses-details .text h2 a:hover{
    color: blue;
}
.ksvesity__allcourses-container_courses-details .text p{
    width: 287.63px;
    height: 85px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14.004px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #2E425C;
}
.text-duration{
    display: flex;
}
.text-duration .one{
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-duration .one h6{
    width: 60px;
    height: 14px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14.004px;
    line-height: 21px;
    color: #2E425C;
    margin-left: 0.3rem;
}
.text-duration .two{
    display: flex;
    align-items: center;
    margin-left: 3rem;
}
.text-duration .two h6{
    width: 85px;
    height: 15px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14.004px;
    line-height: 21px;
    color: #2E425C;
    margin-left: 0.3rem;
}

@media screen and (max-width:1400px) {
    .ksvesity__allcourses-container_courses{
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width:670px) {
    .ksvesity__allcourses-container_courses-details{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px 42.0121px;
        width: 313.83px;
        height: 400.43px;
        background: #FFFFFF;
        box-shadow: 0px 0.420121px 0.840241px rgba(0, 0, 0, 0.1), 0px 1.4004px 4.20121px rgba(0, 0, 0, 0.1);
        border-radius: 4.20121px;
    }
    .ksvesity__allcourses-container h5{
        width: 330px;
        margin-top: 2rem;
    }
    
}
