.ksvesity__pagesbanner{
    display: flex;
    background-color: #F9F9F9;
}
.ksvesity__pagesbanner-container{
    display: flex;
    width: 100%;
    margin: 2rem auto 4rem auto;
    max-width: 1350px;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
}
.ksvesity__pagesbanner-container_details :nth-child(2){
    width: 502.93px;
    height: 72px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2E425C;
}

.ksvesity__pagesbanner-container_details h1{
    width: 293.67px;
    /* height: 90px; */
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 44.8px;
    line-height: 45px;
    letter-spacing: -0.896px;
    color: #0F64D2;
}
/* .ksvesity__pagesbanner-container_details-buttons{

} */
.ksvesity__pagesbanner-container_details-buttons .apply{
    padding: 15px 38.8125px 16.1875px 37.3906px;
    width: 160.2px;
    height: 51.19px;
    background: #0F64D2;
    box-shadow: 0px 10.4px 20.8px rgba(0, 0, 0, 0.2);
    border-radius: 4.8px;
    border: none;
    cursor: pointer;
    color: white;
}
.ksvesity__pagesbanner-container_details-buttons .download{
    padding: 16px 28.9844px 16px 27.7969px;
    width: 202.78px;
    height: 50px;
    background: #F9F9F9;
    box-shadow: 0px 7.2px 14.4px rgba(85, 133, 124, 0.3);
    border-radius: 4.8px;
    border: none;
    cursor: pointer;
    margin-left: .5rem;
    color: #0f64d2;
}
.ksvesity__pagesbanner-container_details :nth-child(4){
    margin-top: 1.5rem;
    width: 232px;
    height: 24px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2E425C;
}

@media screen and (max-width:1450px) {
    .ksvesity__pagesbanner-container_image{
        height: 500px;
        width: 600px; 
    }
    .ksvesity__pagesbanner-container_image img{
        height: 500px;
        width: 600px; 
    }
 
}
@media screen and (max-width:1350px) {
    .ksvesity__pagesbanner-container_image{
        height: 450px;
        width: 550px; 
    }
    .ksvesity__pagesbanner-container_image img{
        height: 450px;
        width: 550px; 
    }

}
@media screen and (max-width:1250px) {
    .ksvesity__pagesbanner-container_image{
        height: 400px;
        width: 500px; 
    }
    .ksvesity__pagesbanner-container_image img{
        height: 400px;
        width: 500px; 
    }

}
@media screen and (max-width:1200px) {
    .ksvesity__pagesbanner-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .ksvesity__pagesbanner-container_image{
        margin-top: 3rem;
    }

}

@media screen and (max-width:650px) {
    .ksvesity__pagesbanner-container_image{
        height: 300px;
        width: 400px; 
    }
    .ksvesity__pagesbanner-container_image img{
        height: 300px;
        width: 400px;
    }
    .ksvesity__pagesbanner-container_details :nth-child(2){
        width: 400.93px;
        height: 55px;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
    }
    
    .ksvesity__pagesbanner-container_details h1{
        width: 293.67px;
        height: 90px;
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
    }
    .ksvesity__pagesbanner-container_details-buttons{
        margin-top: 2rem;
    }
    .ksvesity__pagesbanner-container_details-buttons .apply{
        padding: 15px 38.8125px 16.1875px 37.3906px;
        width: 160.2px;
        height: 51.19px;
        background: #0F64D2;
        box-shadow: 0px 10.4px 20.8px rgba(0, 0, 0, 0.2);
        border-radius: 4.8px;
        border: none;
        cursor: pointer;
        color: white;
    }
    .ksvesity__pagesbanner-container_details-buttons .download{
        padding: 16px 28.9844px 16px 27.7969px;
        width: 202.78px;
        height: 50px;
        background: #F9F9F9;
        box-shadow: 0px 7.2px 14.4px rgba(85, 133, 124, 0.3);
        border-radius: 4.8px;
        border: none;
        font-size: 16px;
      
    }
    .ksvesity__pagesbanner-container_details :nth-child(4){
        margin-top: 1rem;
        width: 232px;
        height: 24px;
        font-weight: 400;
        font-size: 12px;
    }
    
  
}

@media screen and (max-width:450px) {
    .ksvesity__pagesbanner-container_image{
        height: 230px;
        width: 330px; 
    }
    .ksvesity__pagesbanner-container_image img{
        height: 230px;
        width: 330px;
    }
    .ksvesity__pagesbanner-container_details :nth-child(2){
        width: 330.93px;
        height: 55px;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
    }
    
    .ksvesity__pagesbanner-container_details h1{
        width: 293.67px;
        height: 90px;
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
    }
    .ksvesity__pagesbanner-container_details-buttons{
        display: flex;
        align-items: center;
        margin-top: 3rem;
    }
    .ksvesity__pagesbanner-container_details-buttons .apply{
        padding: 12px 24.8125px 12.1875px 24.3906px;
        width: 120.2px;
        height: 45.19px;
        background: #0F64D2;
        box-shadow: 0px 10.4px 20.8px rgba(0, 0, 0, 0.2);
        border-radius: 4.8px;
        font-size: 12px;
  
        border: none;
        cursor: pointer;
        color: white;
    }
    .ksvesity__pagesbanner-container_details-buttons .download{
        padding: 12px 28.9844px 16px 27.7969px;
        width: 180.78px;
        height: 45px;
        background: #F9F9F9;
        box-shadow: 0px 7.2px 14.4px rgba(85, 133, 124, 0.3);
        border-radius: 4.8px;
        border: none;
        font-size: 12px;
      
    }

}
