.ksvesity__application{
    display: flex;
    background: #100F1F;
    height: 428px;
}
.ksvesity__application-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1188px;
    margin: 0 auto;
    align-items: center;
}
.ksvesity__application-container h1{
    color: #F5F5F5;
    text-align: center;
    font-size: 27.2px;
    font-family: Nunito Sans;
    font-weight: 700;
    line-height: 29.92px;
    letter-spacing: -0.272px;
}
.ksvesity__application-container p{
    color: #F5F5F5;
    text-align: center;
    font-size: 16px;
    font-family: Nunito Sans;
    line-height: 24px;
    display: flex;
    width: 668.997px;
    flex-direction: column;
    justify-content: center;
}
.ksvesity__application-container-buttons .one{
    padding: 15px 38.8125px 16.1875px 37.3906px;
    width: 160.2px;
    height: 51.19px;
    background: #0F64D2;
    box-shadow: 0px 10.4px 20.8px rgba(0, 0, 0, 0.2);
    border-radius: 4.8px;
    border: none;
    cursor: pointer;
    color: white;
}
.ksvesity__application-container-buttons .two{
    padding: 16px 28.9844px 16px 27.7969px;
    width: 202.78px;
    height: 50px;
    background: #F9F9F9;
    box-shadow: 0px 7.2px 14.4px rgba(85, 133, 124, 0.3);
    border-radius: 4.8px;
    border: none;
    cursor: pointer;
    margin-left: .5rem;
    color: #0f64d2;
}
@media screen and (max-width:650px) {
    .ksvesity__application-container-buttons .one{
        padding: 15px 32.8125px 16.1875px 31.3906px;
        width: 140.2px;
        height: 51.19px;
    }
    .ksvesity__application-container-buttons .two{
        padding: 16px 23.9844px 16px 22.7969px;
        width: 190px;
        height: 50px;
    }
    .ksvesity__application-container h1{
        font-size: 23.2px;
    }
    .ksvesity__application-container p{
        font-size: 13px;
        width: 400.997px;
    }
}
@media screen and (max-width:420px) {
    .ksvesity__application-container-buttons .one{
        padding: 12px 24.8125px 12.1875px 24.3906px;
        width: 120.2px;
        height: 45.19px;
        font-size: 12px;
    }
    .ksvesity__application-container-buttons .two{
        padding: 12px 28.9844px 16px 27.7969px;
        width: 180.78px;
        height: 45px;
        font-size: 12px;
    }
    .ksvesity__application-container p{
        font-size: 12px;
        width: 350.997px;
    }
}