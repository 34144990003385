@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Nunito:wght@800&family=Permanent+Marker&display=swap');

.ksvesity__grow{
    display: flex;
    background: #FFFAF3;
}
.ksvesity__grow-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1512px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    margin-top: 9rem;
}
.ksvesity__grow-container h1{
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 43px;
    color: #221638;
}
.ksvesity__grow-container .p1{
    width: 601.87px;
    height: 49.19px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #606060;
}
.ksvesity__grow-container_items{
    display: flex;
    margin-top: 2.5rem;
    width: 1190px;
    justify-content: space-between;
}
.ksvesity__grow-container_items-one{
    width: 380px;
    height: 267.56px;
    background: #FFFFFF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ksvesity__grow-container_items-two{
    width: 380px;
    height: 268.56px;
    background: #FFFFFF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ksvesity__grow-container_items-three{
    width: 380px;
    height: 268.56px;
    background: #FFFFFF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ksvesity__grow-container_items-one h2{
    width: 271.92px;
    height: 28px;
    /* font-family: 'Nunito Sans'; */
    font-style: normal;
    font-weight: 700;
    font-size: 22.4px;
    line-height: 27px;
    color: #17355C;
    text-align: start;
    margin-left: -1.5rem;
}
.ksvesity__grow-container_items-one p{
    width: 326px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #2E425C;
}
.ksvesity__grow-container_items-two h2{
    width: 271.92px;
    height: 28px;
    /* font-family: 'Nunito Sans'; */
    font-style: normal;
    font-weight: 700;
    font-size: 22.4px;
    line-height: 27px;
    color: #17355C;
    text-align: start;
    margin-left: -1.5rem;
}
.ksvesity__grow-container_items-two p{
    width: 326px;
    /* height: 100px; */
    /* font-family: 'Nunito Sans'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #2E425C;
}
.ksvesity__grow-container_items-three h2{
    width: 271.92px;
    height: 28px;
    /* font-family: 'Nunito Sans'; */
    font-style: normal;
    font-weight: 700;
    font-size: 22.4px;
    line-height: 27px;
    color: #17355C;
    text-align: start;
    margin-left: -1.5rem;
}
.ksvesity__grow-container_items-three p{
    width: 326px;
    /* font-family: 'Nunito Sans'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #2E425C;
}


@media screen and (max-width:1350px) {
    .ksvesity__grow-container_items{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 1000px;
        grid-gap: 50px;
    }
    .ksvesity__grow-container{
        margin-top: 4rem;
    }
  
}

@media screen and (max-width:1100px) {
    .ksvesity__grow-container_items{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap:40px;
        width: 700px;
    }

}
@media screen and (max-width:900px) {
    .ksvesity__grow-container_items-one,.ksvesity__grow-container_items-two,.ksvesity__grow-container_items-three{
        width: 350px;
    }

}


@media screen and (max-width:850px) {
    .ksvesity__grow-container_items{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 700px;
    }
}
@media screen and (max-width:700px) {
    .ksvesity__grow-container_items{
        width: 600px;
    }
}
@media screen and (max-width:650px) {
    .ksvesity__grow-container .p1{
        width: 400px;
        margin-top: 1rem;
    }
    .ksvesity__grow-container_items{
        width: 550px;
    }
}
@media screen and (max-width:550px) {
    .ksvesity__grow-container_items{
        width: 415px;
    }
}
@media screen and (max-width:415px) {
    .ksvesity__grow-container h1{
        font-size: 30px;
    }
    .ksvesity__grow-container .p1{
        width: 320px;
    }
    .ksvesity__grow-container_items{
        width: 394px;
    }
}
@media screen and (max-width:393px) {
    .ksvesity__grow-container_items-one,.ksvesity__grow-container_items-two,.ksvesity__grow-container_items-three{
        width: 330px;
        height: 268.56px;   
    }
    .ksvesity__grow-container_items-one p,.ksvesity__grow-container_items-two p, .ksvesity__grow-container_items-three p{
        width: 300px;
        font-size: 14px;
    }
    .ksvesity__grow-container_items{
        width: 340px;
    }
}

