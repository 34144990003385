.ksvesity__footer{
    display: flex;
    background: #100F1F;
    color: white;
    /* height: 507px; */
}
.ksvesity__footer-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 1450px;
    margin: 4rem auto 1.8rem auto;
    padding: 0 50px;
}
.ksvesity__footer-container_div1{
    display: flex;
    width: 100%;
    justify-content: space-between;

}
.ksvesity__footer-container_div1-item1 button{
    width: 132px;
    height: 42px;
    background-color: #0F64D2;
    border: none;
    color: #fff;
    cursor: pointer;
}

.ksvesity__footer-container_div1-item2 h2{
    width: 96.5px;
    height: 27px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
}
.ksvesity__footer-container_div1-item2 ul li::before{
    content: '';
    background: #0F64D2;
    padding: 0.1px 2px 0.1px 2px;
    margin-right: 8px;
    width: 7px; 
    height: 7px;
}
.ksvesity__footer-container_div1-item2 ul li{
    margin-bottom: 1rem;
    height: 20px;
    /* font-family: 'Nunito'; */
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #E4E4E4;
}
.ksvesity__footer-container_div1-item2 ul{ 
    list-style: none;
    margin-left: -2.5rem;
}

.ksvesity__footer-container_div1-item3 h2{
    width: 73.07px;
    height: 27px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #F9F9F9;
}
.ksvesity__footer-container_div1-item3 ul li::before{
    content: '';
    background: #0F64D2;
    padding: 0.1px 2px 0.1px 2px;
    margin-right: 8px;
    width: 7px; 
    height: 7px;
}
.ksvesity__footer-container_div1-item3 ul li{
    margin-bottom: 1rem;
    height: 20px;
    /* font-family: 'Nunito'; */
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #E4E4E4;
}
.ksvesity__footer-container_div1-item3 ul{ 
    list-style: none;
    margin-left: -2.5rem;
}
.ksvesity__footer-container_div1-item4 h2{
    width: 73.07px;
    height: 27px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #F9F9F9;
}
.ksvesity__footer-container_div1-item4 p{
    width: 250px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #E4E4E4;
}
.ksvesity__footer-container_div2{
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.ksvesity__footer-container_div2-div{
    display: flex; 
    align-items: center; 
}
.ksvesity__footer-container_div2-div .first-p{
    margin-right: 1rem;
}
.ksvesity__footer-container_div2-div div{
    width: 1px;
    height: 14px;
    background-color: white;
    margin-right: 1rem;
    margin-top: -0.8rem;
}


@media screen and (max-width:1400px) {
    .ksvesity__footer-container{
        max-width: 1300px;
    }
  
}

@media screen and (max-width:1050px) {  
    .ksvesity__footer-container_div1{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        grid-gap: 30px
    }

    .ksvesity__footer-container_div2{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        margin-top: 3rem;
    }

}
@media screen and (max-width:730px) {  
    .ksvesity__footer-container_div1{
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        justify-content: center;
    }

    .ksvesity__footer-container_div2{
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        justify-content: center;
        margin-top: 3rem;
    }
    .ksvesity__footer-container_div2-div div{
        margin-left: -1rem;
        
    }


}

@media screen and (max-width:670px) {
    .ksvesity__footer-container_div1{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .ksvesity__footer-container_div2{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

    }
    .ksvesity__footer-container_div2-div div{
        margin-right: 1rem;
        margin-left: 0;
        
    }

}
