.ksvesity__expectation{
    display: flex;
    background-color: #F9F9F9;
}
.ksvesity__expectation-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;    
}
.ksvesity__expectation-container h1{
    margin-top: 7rem;
    width: 394px;
    height: 43px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 35.2px;
    line-height: 42px;
    text-align: center;
    color: #0F64D2;
}
.ksvesity__expectation-container-div{
    display: flex;
    max-width: 1140px;
    width: 100%;
    justify-content: center;
    margin: 6rem auto 5rem auto;
    flex-wrap: wrap;
}
.items{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    margin-right: 1rem;
}
.items h3{
    height: 24px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 19.2px;
    line-height: 23px;
    color: #0F64D2;
    margin-top: 0.5rem;
}
.items p{
    width: 238.51px;
    height: 72px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2E425C;
}
@media screen and (max-width:500px) {
    .ksvesity__expectation-container h1{
        font-size: 25px;
    }
    .ksvesity__expectation-container-div{
        margin-top: 4rem;
    }
    .items{
        margin-right: 0;
    }
}