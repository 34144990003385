.ksvesity__newsletter{
    display: flex;
    height: 391.38px;
    background: #F9F9F9;
}
.ksvesity__newsletter-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1512px;
    position: relative;
    margin: 0 auto;
}
.ksvesity__newsletter-container h3{
    margin-top: 5rem;
    height: 19px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #0F64D2;
}
.ksvesity__newsletter-container h2{
    width: 486.61px;
    height: 49px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 43px;
    text-align: center;

    color: #221638;
}
.ksvesity__newsletter-container h5{
    height: 22px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #606060;
}
.ksvesity__newsletter-container_email{
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
    margin-top: 1rem;
}
.ksvesity__newsletter-container_email input{
    background: #EAEAEA;
    border-radius: 5px;
    border: none;
    width: 400px;
    padding: 14px;
}
.ksvesity__newsletter-container_email button{
    padding: 11px 29.9531px 9px 55px;
    height: 50px;
    background: #0F64D2;
    border-radius: 0px 5px 5px 0px;
    border: none;
    color: white;
    cursor: pointer;
}
.ksvesity__newsletter-container_design1{
    display: flex;
    align-items: center;
    position: absolute;
    top: 170px;
    left: 360px;
    z-index: 0;
}
.ksvesity__newsletter-container_design1 .design1-two{
    top: -28px;
    left: 45px;
    position: absolute;
    z-index: 1;
}
.ksvesity__newsletter-container_design2{
    display: flex;
    align-items: center;
    position: absolute;
    top: 83px;
    right: 245px;
    z-index: 0;
}
.ksvesity__newsletter-container_design2 .design2-two{
    z-index: 1;
    top: 40px;
    right: -60px;
    position: absolute;
}

@media screen and (max-width:1520px) {
    .ksvesity__newsletter-container_design1{
        left: 340px;
    }
    .ksvesity__newsletter-container_design2{
        right: 240px;
    }
}
@media screen and (max-width:1500px) {
    .ksvesity__newsletter-container_design1{
        left: 330px;
    }
    .ksvesity__newsletter-container_design2{
        right: 235px;
    }
}
@media screen and (max-width:1460px) {
    .ksvesity__newsletter-container_design1{
        left: 315px;
    }
    .ksvesity__newsletter-container_design2{
        right: 210px;
    }

  
}
@media screen and (max-width:1410px) {
    .ksvesity__newsletter-container_design1{
        left: 295px;
    }

    .ksvesity__newsletter-container_design2{
 
        right: 195px;
    }  
}
@media screen and (max-width:1380px) {
    .ksvesity__newsletter-container_design1{
        left: 280px;
    }

    .ksvesity__newsletter-container_design2{
 
        right: 185px;
    }  
}
@media screen and (max-width:1365px) {
    .ksvesity__newsletter-container_design1{
        left: 270px;
    }

    .ksvesity__newsletter-container_design2{
 
        right: 170px;
    }  
}
@media screen and (max-width:1345px) {
    .ksvesity__newsletter-container_design1{
        display: none;
    }

    .ksvesity__newsletter-container_design2{
        display: none;
    } 
}

@media screen and (max-width:700px) {
    .ksvesity__newsletter-container h2{
        width: 450px;
        font-size: 30px;
    }
    .ksvesity__newsletter-container h5{
        margin-top: 0.2rem;
        height: 22px;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        width: 300px;
    }
}


@media screen and (max-width:650px) {
    .ksvesity__newsletter-container_email{
        display: flex;
        flex-direction: column;
    }
    .ksvesity__newsletter-container-button{
        margin-top: 1rem;
    }
    .ksvesity__newsletter-container_email button{
        padding: 11px 29.9531px 9px 29.9531px;
        border-radius: 5px;
 
    }


}
@media screen and (max-width:600px) {
    .ksvesity__newsletter-container h2{
        width: 400px;
        font-size: 27px;
    }

}
@media screen and (max-width:415px) {
    .ksvesity__newsletter-container h2{
        width: 380px;
        font-size: 26px;
    }
    .ksvesity__newsletter-container_email input{
        width: 345px;
    }
}
@media screen and (max-width:393px) {
    .ksvesity__newsletter-container h2{
        width: 320px;
        font-size: 23px;
    }
    .ksvesity__newsletter-container_email input{
        width: 280px;
    }
    .ksvesity__newsletter-container h5{
        width: 260px;
    }

}



