.ksvesity__contactusinfo{
    display: flex;
    padding: 100px;
    background: #0F64D2;
    color: white;
}
.ksvesity__contactusinfo-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.ksvesity__contactusinfo-container h2{
    width: 380px;
    font-family: Nunito Sans;
    font-size: 45px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    color: #FFFFFF;
}
.ksvesity__contactusinfo-container p{
    width: 541.9031372070312px;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;

}
.ksvesity__contactusinfo-container_info{
    display: flex;
    margin-top: 4.5rem;
}
.ksvesity__contactusinfo-container_info-div1,.ksvesity__contactusinfo-container_info-div2,.ksvesity__contactusinfo-container_info-div3,.ksvesity__contactusinfo-container_info-div4{
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: #fff;
    margin-left: 2rem;
    align-items: center;
    color: black;
    border-radius: 5px;

}
.ksvesity__contactusinfo-container_info-div1 h2,.ksvesity__contactusinfo-container_info-div2 h2,.ksvesity__contactusinfo-container_info-div3 h2,.ksvesity__contactusinfo-container_info-div4 h2{
    width: 185px;
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    color: #17355C;
    margin-top: 0.5rem;
}
.ksvesity__contactusinfo-container_info-div1 p,.ksvesity__contactusinfo-container_info-div2 p,.ksvesity__contactusinfo-container_info-div3 p,.ksvesity__contactusinfo-container_info-div4 p{
    width: 170.2624969482422px;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #2E425C;
}
@media screen and (max-width:1200px) {
    .ksvesity__contactusinfo-container_info{
        display: grid;
        grid-template-columns: 300px 300px;
        grid-gap: 30px;
    }
}
@media screen and (max-width:730px) {
    .ksvesity__contactusinfo-container_info{
        display: grid;
        grid-template-columns: 250px 250px;
        grid-gap: 30px;
    }
    .ksvesity__contactusinfo-container_info-div1,.ksvesity__contactusinfo-container_info-div2,.ksvesity__contactusinfo-container_info-div3,.ksvesity__contactusinfo-container_info-div4{
        margin-left: 1rem;
    }
}
@media screen and (max-width:600px) {
    .ksvesity__contactusinfo-container_info{
        display: grid;
        grid-template-columns: 300px;
    }
    .ksvesity__contactusinfo-container h2:nth-child(1){
        font-size: 33px;
    }    
    .ksvesity__contactusinfo-container p:nth-child(2){
        width: 400px;

    }

}
@media screen and (max-width:423px) {
    .ksvesity__contactusinfo-container h2:nth-child(1){
        font-size: 31px;
    }    
    .ksvesity__contactusinfo-container p:nth-child(2){
        width: 300px;

    }

}