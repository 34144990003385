@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Nunito:wght@800&family=Permanent+Marker&display=swap');

.ksvesity__banner2{
    display: flex;
    background: #F9FBFF;
    justify-content: center;
    align-items: center;
    padding: 50px;
}
.skill{
    width: 585px;
    height: 389.84px;
    border-radius: 1rem;
}
.ksvesity__banner2-container{
    display: flex;
    width: 100%;
    max-width: 1230px;
    margin: 0 auto;
    justify-content: space-between;
    padding: 0 50px;
    align-items: center;
    /* height: 589.84px; */

}
.ksvesity__banner2-container_img{
    margin-left: -2.5rem;
}
.ksvesity__banner2-container_details{
    margin-top: 5rem;
    margin-left: 2.5rem;

}
.ksvesity__banner2-container_details h2{
    width: 544.64px;
    color: #221638;
    height: 135.38px;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 43px;
    display: flex;
    align-items: center;
    font-family: 'Oswald', sans-serif;

    
    
}
.ksvesity__banner2-container_details p{
    width: 567.83px;
    height: 49.19px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #606060;
}

.ksvesity__banner2-container_details-skills{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 2rem;
}

.ksvesity__banner2-container_details-skills .item{
    display: flex;
    align-items: center;
}

.ksvesity__banner2-container_details-skills .item h3{
    margin-left: 1rem;
    width: 200px;
    height: 27px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #221638;
}

.ksvesity__banner2-container_details-button{
    display: flex;
    margin-top: 2rem;
    padding: 15px 28px 16.1875px 28.7969px;
    height: 51.19px;
    background: #0F64D2;
    box-shadow: 0px 7.2px 14.4px rgba(85, 133, 124, 0.3);
    border-radius: 4.8px;
    border: none;
    color: white;
}


@media screen and (max-width:1350px) {
    .ksvesity__banner2-container{
        padding: 0 50px;
    }
    .ksvesity__banner2-container_img{
        width: 500px;
        height: 389.84px;
    }
    .ksvesity__banner2-container_img img{
        width: 500px;
        height: 389.84px;
    }
  
}
@media screen and (max-width:1250px) {

    .ksvesity__banner2-container_img{
        width: 470px;
        height: 365.84px;
    }
    .ksvesity__banner2-container_img img{
        width: 470px;
        height: 365.84px;
    }
  
}
@media screen and (max-width:1200px) {
    .ksvesity__banner2-container{
        display: flex;
        flex-direction: column-reverse;
        /* height: 1000px; */
        justify-content: space-around;
        align-items: center;
    }
    .ksvesity__banner2-container_img{
        width: 500px;
        height: 389.84px;
        margin-top: 3rem;
    }
    .ksvesity__banner2-container_img img{
        width: 500px;
        height: 389.84px;
    }
    .ksvesity__banner2-container_details{
        margin-top: 0rem;
        /* margin-left: 2.5rem; */
    
    }
  

}
@media screen and (max-width:700px) {
    .ksvesity__banner2-container_details h1{
        width: 490px;
    }
    .ksvesity__banner2-container_details p{
        width: 490px;
    }
}
@media screen and (max-width:650px) {
    .ksvesity__banner2-container_details h1{
        width: 440px;
        color: #221638;
        height: 135.38px;
        font-style: normal;
        font-weight: 800;
        font-size: 34px;
        line-height: 43px;
        display: flex;
        align-items: center;
        font-family: 'Oswald', sans-serif;
        
    }
    .ksvesity__banner2-container_details p{
        width: 440px;
        height: 49.19px;
        font-weight: 600;
        font-size: 14.5px;
    }
    
    .ksvesity__banner2-container_details-skills{
        display: grid;
        grid-template-columns: 0.8fr 0.8fr;
    }
    .ksvesity__banner2-container_img{
        width: 380px;
        height: 310px;
    }
    .ksvesity__banner2-container_img img{
        width: 380px;
        height: 310px;
    }
}
@media screen and (max-width:550px) {
    .ksvesity__banner2-container{
        height: 800px;
    }
    .ksvesity__banner2-container_details{
        margin-top: -2.5rem;
    }
    .ksvesity__banner2-container_details h2{
        width: 305px;
        font-size: 26px;
    }
    .ksvesity__banner2-container_details p{
        width: 305px;
        margin-top: 2rem;

    }
    .ksvesity__banner2-container_img{
        width: 305px;
        height: 260px;
        margin-top: -4rem;
    }
    .ksvesity__banner2-container_img img{
        width: 305px;
        height: 260px;
    }
    .ksvesity__banner2-container_details-skills .item h3{
        width: 150px;
        height: 27px;
        font-size: 12px;
        line-height: 27px;
    }
    .ksvesity__banner2{
        padding: 20px 0 0 0;
    }
} 
@media screen and (max-width:415px) {
    .ksvesity__banner2-container_img{
        height: 250px;
        width: 280px;
        margin-top: -2rem;
    }
    .ksvesity__banner2-container_img img{
        height: 250px;
        width: 280px;
    }
    .ksvesity__banner2-container_details h2{
        width: 290px;
        height: 135.38px;
        font-weight: 800;
        font-size: 22px;
        line-height: 32px;
    }
    .ksvesity__banner2-container_details p{
        width: 280px;
        height: 49.19px;
        font-size: 15px;
        line-height: 29px;
    }
    .ksvesity__banner2-container_details-skills{
        display: grid;
        grid-template-columns: 0.5fr 0.8fr;
    }
    .ksvesity__banner2-container_details-skills .item h3{
        width: 134px;
        height: 27px;
        font-size: 12px;
        line-height: 27px;
    }
}

