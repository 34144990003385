.ksvesity__kidzonebanner2{
    display: flex;
}
.ksvesity__kidzonebanner2-container{
    display: flex;

}
.ksvesity__kidzonebanner2-container-div1 {
    position: relative;
}
.ksvesity__kidzonebanner2-container-div1::before{
    z-index: 2;
    background: #000000B2;
    inset: 0;
    content: ''; 
    position: absolute;
}
.ksvesity__kidzonebanner2-container-div1_div, .ksvesity__kidzonebanner2-container-div2_div,.ksvesity__kidzonebanner2-container-div3_div{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    inset: 0;
    z-index: 5;
}
.ksvesity__kidzonebanner2-container-div2 {
    position: relative;
}
.ksvesity__kidzonebanner2-container-div2::before{
    z-index: 2;
    background: #000000B2;
    inset: 0;
    content: ''; 
    position: absolute;
}

.ksvesity__kidzonebanner2-container-div3 {
    position: relative;
}
.ksvesity__kidzonebanner2-container-div3::before{
    z-index: 2;
    background: #000000B2;
    inset: 0;
    content: ''; 
    position: absolute;
}
.ksvesity__kidzonebanner2-container-div1_div h3,.ksvesity__kidzonebanner2-container-div2_div h3,.ksvesity__kidzonebanner2-container-div3_div h3{
    margin-top: 1rem;
}
@media screen and (max-width:1511px) {
    .ksvesity__kidzonebanner2-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 3rem;
    }
    .ksvesity__kidzonebanner2-container-div1, .ksvesity__kidzonebanner2-container-div2,.ksvesity__kidzonebanner2-container-div3{
        margin-top: 2rem;
    }
    .ksvesity__kidzonebanner2-container-div1 .image1, .ksvesity__kidzonebanner2-container-div2 .image2,  .ksvesity__kidzonebanner2-container-div3 .image3 {
        width: 600px;
        height: 480px;
    }
}
@media screen and (max-width:1200px) {

    .ksvesity__kidzonebanner2-container-div1 .image1, .ksvesity__kidzonebanner2-container-div2 .image2,  .ksvesity__kidzonebanner2-container-div3 .image3 {
        height: 400px;
        width: 550px;
    }
}
@media screen and (max-width:650px) {

    .ksvesity__kidzonebanner2-container-div1 .image1, .ksvesity__kidzonebanner2-container-div2 .image2,  .ksvesity__kidzonebanner2-container-div3 .image3 {
        height: 380px;
        width: 450px;
    }
}
@media screen and (max-width:550px) {

    .ksvesity__kidzonebanner2-container-div1 .image1, .ksvesity__kidzonebanner2-container-div2 .image2,  .ksvesity__kidzonebanner2-container-div3 .image3 {
        height: 335px;
        width: 360px;
    }
}
@media screen and (max-width:500px) {
    .ksvesity__kidzonebanner2-container-div1 .image1, .ksvesity__kidzonebanner2-container-div2 .image2,  .ksvesity__kidzonebanner2-container-div3 .image3 {
        height: 295px;
        width: 310px;
    }
    .ksvesity__kidzonebanner2-container-div1_div h3,.ksvesity__kidzonebanner2-container-div2_div h3,.ksvesity__kidzonebanner2-container-div3_div h3{
        font-size: 20px;
    }
}

@media screen and (max-width:393px) {
    .ksvesity__kidzonebanner2-container-div1 .image1, .ksvesity__kidzonebanner2-container-div2 .image2,  .ksvesity__kidzonebanner2-container-div3 .image3 {
        height: 230px;
        width: 250px;
    }
    .ksvesity__kidzonebanner2-container-div1_div h3,.ksvesity__kidzonebanner2-container-div2_div h3,.ksvesity__kidzonebanner2-container-div3_div h3{
        font-size: 15px;
    }
}