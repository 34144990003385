.ksvesity__aboutusvolunteer{
    display: flex;
    background-color: #FEF8EF;
    padding: 70px;
}
.ksvesity__aboutusvolunteer-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    max-width: 1300px;
    align-items: center;
    justify-content: center;
}
.ksvesity__aboutusvolunteer-container h5{
    width: 221.5906219482422px;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #0F64D2;
}
.ksvesity__aboutusvolunteer-container h2{
    width: 584.3406372070312px;
    font-family: Nunito;
    font-size: 36px;
    font-weight: 800;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: center;
    color: #221638;
}
.ksvesity__aboutusvolunteer-container p{
    width: 588.0125122070312px;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #606060;
}
.ksvesity__aboutusvolunteer-container button{
    width: Hug(200.09px);
    height: Hug(46px);
    padding: 11px 30.09375px 11px 54px;
    border-radius: 5px;
    background-color: #0F64D2;
    color: white;
    border: none;
    margin-top: 1rem;
}
@media screen and (max-width:650px) {
    .ksvesity__aboutusvolunteer-container h2{
        width: 450px;
        font-size: 25px;
    }
    .ksvesity__aboutusvolunteer-container p{
        width: 450px;
        font-size: 16px;
    }
}
@media screen and (max-width:550px) {
    .ksvesity__aboutusvolunteer-container h2{
        width: 315px;
        font-size: 20px;
    }
    .ksvesity__aboutusvolunteer-container p{
        width: 320px;
    }
}
@media screen and (max-width:432px) {
    .ksvesity__aboutusvolunteer-container h2{
        width: 295px;
        font-size: 20px;
    }
    .ksvesity__aboutusvolunteer-container p{
        width: 295px;
        font-size: 13px;
    }
}