@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');

.ksvesity__kidzoneenroll{
    display: flex;

}
.ksvesity__kidzoneenroll-container_item1{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    max-width: 1330px;
    margin: 0 auto;
    padding: 3rem 0 3rem 0;
}
/* .ksvesity__kidzoneenroll-container_item1-info{

} */
.ksvesity__kidzoneenroll-container_item1-info h4{
    height: 19px;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #0F64D2;

}
.ksvesity__kidzoneenroll-container_item1-info h2{
    width: 549.9968872070312px;
    font-family: Nunito;
    font-size: 36px;
    font-weight: 800;
    /* line-height: 43px; */
    letter-spacing: 0em;
    text-align: left;
    color: #221638;
}
.ksvesity__kidzoneenroll-container_item1-info p{
    width: 549.9968872070312px;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #606060;

}
.ksvesity__kidzoneenroll-container_item1-info-about h3{
    font-family: Nunito;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #221638;
    margin-left: 0.5rem;
}
.ksvesity__kidzoneenroll-container_item1-info-about{
    display: grid;
    grid-template-columns: 290px 290px;
}
.ksvesity__kidzoneenroll-container_item1-info-about div{
    display: flex;
    align-items: center;
}
.ksvesity__kidzoneenroll-container_item1-info-button{
    width: Hug (130.52px);
    height: Hug (46px);
    top: 359.9375px;
    padding: 11px 30.515625px 11px 21px;
    border-radius: 5px;
    background: #0F64D2;
    color: white;
    border: none;
    margin-top: 1rem;
}
@media screen and (max-width:1200px) {
    .ksvesity__kidzoneenroll-container_item1{
        flex-direction: column;
    }
    .ksvesity__kidzoneenroll-container_item1-info{
        margin-top: 2.5rem;
    }
}

@media screen and (max-width:650px) {
    .ksvesity__kidzoneenroll-container_item1-info h2{
        width: 450px;
        font-size: 22px;
    }
    .ksvesity__kidzoneenroll-container_item1-info p{
        width: 450px;
        margin-bottom: 1.5rem;
    }
    
    .ksvesity__kidzoneenroll-container_item1-info-about{
        grid-template-columns: 240px 240px;
    }
    .ksvesity__kidzoneenroll-container_item1-image{
        width: 450px;
        height: 370px
    }
    .ksvesity__kidzoneenroll-container_item1-image img{
        width: 450px;
        height: 370px
    }
    .ksvesity__kidzoneenroll-container_item1-info-about .item h3{
        width: 130px;
        height: 27px;
        font-size: 14px;
        line-height: 27px;
     }
}

@media screen and (max-width:550px) {
    .ksvesity__kidzoneenroll-container_item1-info h2{
        width: 315px;
        font-size: 20px;
    }
    .ksvesity__kidzoneenroll-container_item1-info p{
        width: 315px;
        margin-bottom: 1.5rem;
    }
    
    .ksvesity__kidzoneenroll-container_item1-info-about{
        grid-template-columns: 180px 180px;
    }
    .ksvesity__kidzoneenroll-container_item1-image{
        height: 335px;
        width: 360px;
    }
    .ksvesity__kidzoneenroll-container_item1-image img{
        height: 335px;
        width: 360px;
    }
    .ksvesity__kidzoneenroll-container_item1-info-about .item h3{
        width: 130px;
        height: 27px;
        font-size: 12px;
        line-height: 27px;
     }
}

@media screen and (max-width:432px) {
    .ksvesity__kidzoneenroll-container_item1-image{
        height: 263px;
        width: 293px;
    }
    .ksvesity__kidzoneenroll-container_item1-image img{
        height: 263px;
        width: 293px;
    }
    .ksvesity__kidzoneenroll-container_item1-info h2{
        width: 295px;
        color: #221638;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        font-family: 'Oswald', sans-serif;
    }
    .ksvesity__kidzoneenroll-container_item1-info p{
        width: 295px;
        /* font-family: 'Oswald', sans-serif; */
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #606060;

    }
    .ksvesity__kidzoneenroll-container_item1-info-about {
        display: grid;
        grid-template-columns: 150px 150px;
        grid-gap: 5px

        /* width: 295px; */
    }
    .ksvesity__kidzone-container_item1-info-about .item h3{
        width: 100px;
        height: 20px;
        font-size: 11px;
        line-height: 25px;
     }


}
@media screen and (max-width:395px) {
    .ksvesity__kidzoneenroll-container_item1-image{
        height: 230px;
        width: 270px;
    }
    .ksvesity__kidzoneenroll-container_item1-image img{
        height: 230px;
        width: 270px;
    }
    .ksvesity__kidzoneenroll-container_item1-info h2{
        width: 280px;
        color: #221638;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;

    }
    .ksvesity__kidzoneenroll-container_item1-info p{
        width: 280px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #606060;

    }
    .ksvesity__kidzoneenroll-container_item1-info-about {
        display: grid;
        grid-template-columns: 140px 140px;
        grid-gap: 5px
    }

}
