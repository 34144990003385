@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');

.ksvesity__kidzone{
    display: flex;
    background: #F5F7FA;
}
.ksvesity__kidzone-container{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    max-width: 1512px;
    margin: 0 auto;
    margin-top: 4rem;
    align-items: center;
    /* padding: 0 50px; */
    margin-bottom: 4rem;
}
.ksvesity__kidzone-container_item1{
    display: flex;

}
.ksvesity__kidzone-container_item1-info{
    /* height: 413px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.ksvesity__kidzone-container_item1-info h4{
    width: 120.15px;
    height: 19px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #0F64D2;   
}
.ksvesity__kidzone-container_item1-info h2{
    width: 579.2px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 31px;
    color: #221638;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;

}
.ksvesity__kidzone-container_item1-info p{
    width: 550px;
    height: 76.38px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #606060;
}
.ksvesity__kidzone-container_item1-info-about{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.ksvesity__kidzone-container_item1-info-button{
    margin-top: 1.2rem;
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 127.52px;
    height: 46px;
    background: #0F64D2;
    border-radius: 5px;
}
.ksvesity__kidzone-container_item1-info-about .item{
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}
.ksvesity__kidzone-container_item1-info-about .item h3{
   margin-left: 1rem;
   width: 200px;
   height: 27px;
   font-family: 'Nunito';
   font-style: normal;
   font-weight: 700;
   font-size: 18px;
   line-height: 27px;
   color: #221638;
}

.ksvesity__kidzone-container_item1-image img{
    height: 408px;
    width: 613px;
}

@media screen and (max-width:1350px) {
    .ksvesity__kidzone-container_item1{
        padding: 0 50px;
    }

    .ksvesity__kidzone-container_item1-image{
        width: 560px;
        height: 373px
    }
    .ksvesity__kidzone-container_item1-image img{
        width: 560px;
        height: 373px
    }
  
}
@media screen and (max-width:1250px) {

    .ksvesity__kidzone-container_item1-image{
        width: 530px;
        height: 353px
    }
    .ksvesity__kidzone-container_item1-image img{
        width: 530px;
        height: 353px
    }
  
}
@media screen and (max-width:1200px) {
    .ksvesity__kidzone-container_item1{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

}

@media screen and (max-width:650px) {
    .ksvesity__kidzone-container_item1-info h2{
        width: 440px;
        font-size: 30px;
    }
    .ksvesity__kidzone-container_item1-info p{
        width: 440px;
        margin-bottom: 1.5rem;
    }
    
    .ksvesity__kidzone-container_item1-info-about{
        display: grid;
        grid-template-columns: 0.8fr 0.8fr;
    }
    .ksvesity__kidzone-container_item1-image{
        width: 440px;
        height: 293px
    }
    .ksvesity__kidzone-container_item1-image img{
        width: 440px;
        height: 293px
    }
    .ksvesity__kidzone-container_item1-info-about .item h3{
        width: 130px;
        height: 27px;
        font-size: 14px;
        line-height: 27px;
     }

    
}
@media screen and (max-width:550px) {

    .ksvesity__kidzone-container_item1-info h2{
        width: 315px;
        font-size: 23px;
        line-height: 30px;
    }
    .ksvesity__kidzone-container_item1-info p{
        width: 315px;
        margin-bottom: 3rem;
        margin-top: 1rem;
    }
    .ksvesity__kidzone-container_item1-image{
        width: 380px;
        height: 253px
    }
    .ksvesity__kidzone-container_item1-image img{
        width: 380px;
        height: 253px
    }
    .ksvesity__kidzone-container_item1-info-about .item h3{
        width: 130px;
        height: 27px;
        font-size: 12px;
        line-height: 27px;
     }
     .ksvesity__kidzone-container_item1-info-about{
        margin-top: 1rem;
     }

}

@media screen and (max-width:432px) {
    .ksvesity__kidzone-container_item1-image{
        height: 230px;
        width: 300px;
    }
    .ksvesity__kidzone-container_item1-image img{
        height: 230px;
        width: 300px;
    }
    .ksvesity__kidzone-container_item1-info h2{
        width: 295px;
        color: #221638;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 25px;
        font-family: 'Oswald', sans-serif;
    }
    .ksvesity__kidzone-container_item1-info p{
        width: 295px;
        height: 76.38px;
        /* font-family: 'Oswald', sans-serif; */
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        color: #606060;

    }
    .ksvesity__kidzone-container_item1-info-about {
        display: grid;
        grid-template-columns: 0.5fr 0.8fr;
        grid-gap: 5px;
        margin-top: -1rem;
        /* width: 295px; */
    }
    .ksvesity__kidzone-container_item1-info-about .item h3{
        width: 100px;
        height: 20px;
        font-size: 11px;
        line-height: 25px;

        
     }


}
@media screen and (max-width:395px) {
    .ksvesity__kidzone-container_item1-image{
        height: 210px;
        width: 270px;
    }
    .ksvesity__kidzone-container_item1-image img{
        height: 210px;
        width: 270px;
    }
    .ksvesity__kidzone-container_item1-info h2{
        width: 280px;
        color: #221638;
        height: 135.38px;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;

    }
    .ksvesity__kidzone-container_item1-info p{
        width: 280px;
        height: 76.38px;
        /* font-family: 'Oswald', sans-serif; */
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        color: #606060;

    }
    .ksvesity__kidzone-container_item1-info-about {
        display: grid;
        grid-template-columns: 0.5fr 0.8fr;
        grid-gap: 5px;
        margin-top: 0.5rem;
    }
    .ksvesity__kidzone-container_item1{
        width: 270px;
    }
}

