.ksvesity__aboutusmission{
    display: flex;

}
.ksvesity__aboutusmission-container{
    display: flex;
    width: 100%;
    max-width: 1395px;
    margin: 0 auto;
    padding: 30px 90px;
    align-items: center;
    justify-content: space-between;
}
.ksvesity__aboutusmission-container-image{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.ksvesity__aboutusmission-container-info h2{
    width: 526.4187622070312px;
    font-family: Nunito;
    font-size: 48px;
    font-weight: 800;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}
.ksvesity__aboutusmission-container-info p{
    width: 447.60626220703125px;
    font-family: Nunito;
    font-size: 17px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #382F2F;
}
@media screen and (max-width: 1300px) {
    .ksvesity__aboutusmission-container-image img{
        width: 510px;
        height: 436px;
    }
    .ksvesity__aboutusmission-container-info h2{
        font-size: 44px;
    }
}
@media screen and (max-width: 1200px) {
    .ksvesity__aboutusmission-container{
        display: flex;
        flex-direction: column-reverse;
    }
    .ksvesity__aboutusmission-container-info{
        margin-top: 2.5rem;
    }
}
@media screen and (max-width:650px) {
    .ksvesity__aboutusmission-container-info h2{
        width: 450px;
        font-size: 25px;
    }
    .ksvesity__aboutusmission-container-info p{
        width: 450px;
        font-size: 16px;
    }

    .ksvesity__aboutusmission-container-image img{
        width: 450px;
        height: 370px
    }

}

@media screen and (max-width:550px) {
    .ksvesity__aboutusmission-container-info h2{
        width: 315px;
        font-size: 20px;
    }
    .ksvesity__aboutusmission-container-info p{
        width: 315px;
    }
    .ksvesity__aboutusmission-container-image img{
        height: 335px;
        width: 360px;
    }
}

@media screen and (max-width:432px) {

    .ksvesity__aboutusmission-container-image img{
        height: 263px;
        width: 293px;
    }
    .ksvesity__aboutusmission-container-info h2{
        width: 295px;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        font-family: 'Oswald', sans-serif;
    }
    .ksvesity__aboutusmission-container-info p{
        width: 295px;
        /* font-family: 'Oswald', sans-serif; */
        font-style: normal;
        font-weight: 400;
        font-size: 13px;

    }

}
@media screen and (max-width:395px) {

    .ksvesity__aboutusmission-container-image img{
        height: 230px;
        width: 270px;
    }
    .ksvesity__aboutusmission-container-info h2{
        width: 280px;
        color: #221638;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;

    }
    .ksvesity__aboutusmission-container-info p{
        width: 280px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

    }

}
