.ksvesity__kidzonebanner{
    display: flex;
}
.ksvesity__kidzonebanner-container{
    display: flex;
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
}
.ksvesity__kidzonebanner-container_details h1{
    font-family: Nunito;
    Width: 458.58px;
    font-size: 59px;
    font-weight: 800;
    /* line-height: 71px; */
    text-align: left;
    color: #221638;
}
.ksvesity__kidzonebanner-container_details p{
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    width: 567.82px

}
.ksvesity__kidzonebanner-container_details-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding: 11px 16px;
    width: 150px;
    height: 46px;
    background: #0F64D2;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    color: white;

}
@media screen and (max-width:1450px) {
    .ksvesity__kidzonebanner-container_image{
        height: 500px;
        width: 650px;
    }
    .ksvesity__kidzonebanner-container_image img{
        height: 500px;
        width: 650px;
    }
}
@media screen and (max-width:1350px) {
    .ksvesity__kidzonebanner-container_details h1{
        font-size: 54px;
        width: 700px;
    }
    .ksvesity__kidzonebanner-container_image{
        height: 450px;
        width: 600px;
    }
    .ksvesity__kidzonebanner-container_image img{
        height: 450px;
        width: 600px;
    }
    .ksvesity__Banner-container_details .software{
        font-size: 47px;
    }
}
@media screen and (max-width:1250px) {
    .ksvesity__kidzonebanner-container_details h1{
        font-size: 50px;
        width: 600px;

    }
    .ksvesity__kidzonebanner-container_image{
        height: 400px;
        width: 550px;
    }
    .ksvesity__kidzonebanner-container_image img{
        height: 400px;
        width: 550px;
    }
    .ksvesity__Banner-container_details .software{
        font-size: 43px;
    }
}
@media screen and (max-width:1200px) {
    .ksvesity__kidzonebanner-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 4rem;
    }
    .ksvesity__kidzonebanner-container_image{
        margin-top: 5rem;
        margin-bottom: 3rem;
    }

}
@media screen and (max-width:850px) {

}
@media screen and (max-width:650px) {
    .ksvesity__kidzonebanner-container_image{
        height: 380px;
        width: 450px;

    }
    .ksvesity__kidzonebanner-container_image img{
        height: 380px;
        width: 450px;
    }
    .ksvesity__kidzonebanner-container_details  h1{
        font-size: 42px;
        width: 500px;
        line-height: 55px;
    }
    .ksvesity__kidzonebanner-container_details p{
        width: 450px;
    }
  
}
@media screen and (max-width:550px) {
    .ksvesity__kidzonebanner-container_image{
        height: 335px;
        width: 360px;
    }
    .ksvesity__kidzonebanner-container_image img{
        height: 335px;
        width: 360px;
    }
    .ksvesity__kidzonebanner-container_details p{
        width: 370px;
    }
    .ksvesity__kidzonebanner-container_details h1{
        font-size: 35px;
        width: 400px;
        line-height: 52px;

    }
    .ksvesity__Banner-container_details .software{
        font-size: 32px;
    }
}
@media screen and (max-width:500px) {
    .ksvesity__kidzonebanner-container_image{
        height: 295px;
        width: 310px;
    }
    .ksvesity__kidzonebanner-container_image img{
        height: 295px;
        width: 310px;
    }
    .ksvesity__kidzonebanner-container_details p:nth-child(3){
        width: 310px;
    }
    .ksvesity__kidzonebanner-container_details h1{
        font-size: 32px;
        width: 370px;
        line-height: 52px;
    }
    .ksvesity__Banner-container_details .software{
        font-size: 28px;
    }
}
@media screen and (max-width:415px) {
    .ksvesity__kidzonebanner-container_image{
        height: 260px;
        width: 290px;
    }
    .ksvesity__kidzonebanner-container_image img{
        height: 260px;
        width: 290px;
    }
    .ksvesity__kidzonebanner-container_details h1{
        font-size: 29px;
        width: 310px;
        line-height: 41px;
    }

}
@media screen and (max-width:393px) {
    .ksvesity__kidzonebanner-container_image{
        height: 220px;
        width: 250px;
    }
    .ksvesity__kidzonebanner-container_image img{
        height: 230px;
        width: 250px;
    }
    .ksvesity__kidzonebanner-container_details h1{
        font-size: 27px;
        width: 310px;
        line-height: 41px;
    }
    .ksvesity__kidzonebanner-container_details p{
        width: 330px;
    }

}
