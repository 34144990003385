.ksvesity__testimony{
    display: flex;
    background: #FFFAF3;
    position: relative;
}
.ksvesity__testimony-container{
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    width: 100%;
    max-width: 1200px;
    margin: 6rem auto 15rem auto;
}
.ksvesity__testimony-container-cards{
    display: flex;
    justify-content: space-between;
}
.ksvesity__testimony-container-circle{
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}
.ksvesity__testimony-container-circle .two{
    margin-left: .5rem;
}
.ksvesity__testimony-container-image{
    position: absolute;
    bottom: 0;
    right: 0;
}
@media screen and (max-width:1250px) {
    .ksvesity__testimony-container-cards{
        flex-direction: column;
        width: 100%;
        align-items: center;
        height: 700px;
        justify-content: space-between;
    } 
}
@media screen and (max-width:650px) {
    .ksvesity__testimony-container-cards{
        flex-direction: column;
        width: 100%;
        align-items: center;
        height: 850px;
        justify-content: space-between;
    } 
}
