.ksvesity__coursesummary{
    display: flex;
    background-color: #F9F9F9;
}
.ksvesity__coursesummary_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFAF3;
    width: 100%;
    height: 360px;
    margin: 2rem 0 4rem 0;
    padding: 0 300px;
}
.ksvesity__coursesummary_container-div1 , .ksvesity__coursesummary_container-div2, .ksvesity__coursesummary_container-div3 , .ksvesity__coursesummary_container-div4 , .ksvesity__coursesummary_container-div5 , .ksvesity__coursesummary_container-div6 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ksvesity__coursesummary_container-div1 h4, .ksvesity__coursesummary_container-div2 h4, .ksvesity__coursesummary_container-div3 h4, .ksvesity__coursesummary_container-div4 h4, .ksvesity__coursesummary_container-div5 h4, .ksvesity__coursesummary_container-div6 h4{
    height: 24px;
    color: #0F64D2;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 0.5rem;
 }
 .ksvesity__coursesummary_container-div1 h2,.ksvesity__coursesummary_container-div2 h2, .ksvesity__coursesummary_container-div3 h2, .ksvesity__coursesummary_container-div4 h2, .ksvesity__coursesummary_container-div5 h2, .ksvesity__coursesummary_container-div6 h2{        
    height: 29px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #2E425C;
    display: flex;
    flex-direction: column;
    align-items: center;
 }
 .ksvesity__coursesummary_container-div2 h2{
    width: 122.29px;
    text-align: center;
}

.ksvesity__coursesummary_container-div1 h2 span{
    width: 89px;
    height: 18px;
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #2E425C;
}
@media screen and (max-width:1400px) {
    .ksvesity__coursesummary_container{
        padding: 0 200px;
    }
}
@media screen and (max-width:1200px) {
    .ksvesity__coursesummary_container{
        padding: 0 100px;
    }
}
@media screen and (max-width:1000px) {
    .ksvesity__coursesummary_container-div1 h2, .ksvesity__coursesummary_container-div2 h2, .ksvesity__coursesummary_container-div3 h2, .ksvesity__coursesummary_container-div4 h2, .ksvesity__coursesummary_container-div5 h2, .ksvesity__coursesummary_container-div6 h2{
        font-size: 18px;
    }
    .ksvesity__coursesummary_container-div2 h2{
        width:110px ;
    }

}
@media screen and (max-width:800px) {
    .ksvesity__coursesummary_container-div1 h2, .ksvesity__coursesummary_container-div2 h2, .ksvesity__coursesummary_container-div3 h2, .ksvesity__coursesummary_container-div4 h2, .ksvesity__coursesummary_container-div5 h2, .ksvesity__coursesummary_container-div6 h2{
        font-size: 15px;
    }
    .ksvesity__coursesummary_container-div1 h4, .ksvesity__coursesummary_container-div2 h4, .ksvesity__coursesummary_container-div3 h4, .ksvesity__coursesummary_container-div4 h4, .ksvesity__coursesummary_container-div5 h4, .ksvesity__coursesummary_container-div6 h4{
        font-size: 12px;
    }
    .ksvesity__coursesummary_container-div2 h2{
        width:90px ;
    }

}
@media screen and (max-width:730px) {
    .ksvesity__coursesummary_container{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0 20px ;
    }
}
@media screen and (max-width:450px) {
    .ksvesity__coursesummary_container-div1 h2, .ksvesity__coursesummary_container-div2 h2, .ksvesity__coursesummary_container-div3 h2, .ksvesity__coursesummary_container-div4 h2, .ksvesity__coursesummary_container-div5 h2, .ksvesity__coursesummary_container-div6 h2{
        font-size: 12.3px;
    }
    .ksvesity__coursesummary_container-div2 h2{
        width:70px ;
    }
}
