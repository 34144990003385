.ksvesity__funfact{
    display: flex;
    background: #FFFAF3;
}
.ksvesity__funfact-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1512px;
    margin: 10rem auto 10rem auto;
    justify-content: center;
    align-items: center;

}
.ksvesity__funfact-container h5{
    width: 69px;
    height: 24px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2E425C;
}
.ksvesity__funfact-container .h1{
    width: 227px;
    height: 43px;
    /* font-family: 'Nunito Sans'; */
    font-style: normal;
    font-weight: 700;
    font-size: 35.2px;
    line-height: 42px;
    color: #17355C;
}

.ksvesity__funfact-container_items{
    display: flex;
    width: 75%;
    justify-content: space-between;
    margin-top: 4rem;
}
.ksvesity__funfact-container_items-one{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ksvesity__funfact-container_items-two{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ksvesity__funfact-container_items-three{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ksvesity__funfact-container_items-four{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ksvesity__funfact-container_items-one h1{
    width: 171px;
    height: 60px;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 63px;
    line-height: 24px;
    color: #2E425C;
}
.ksvesity__funfact-container_items-two h1{
    width: 233px;
    height: 60px;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 24px;
    color: #2E425C;
}
.ksvesity__funfact-container_items-three h1{
    width: 148px;
    height: 60px;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 24px;
    color: #2E425C;
}
.ksvesity__funfact-container_items-four h1{
    width: 222px;
    height: 60px;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 24px;
    color: #2E425C;
}
.ksvesity__funfact-container_items-one p{
    width: 120px;
    height: 24px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2E425C;
    margin-top: -1.7rem;
    
}
.ksvesity__funfact-container_items-two p{
    width: 147px;
    height: 24px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2E425C;
    margin-top: -1.7rem;

}
.ksvesity__funfact-container_items-three p{
    width: 61px;
    height: 24px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2E425C;
    margin-top: -1.7rem;

}
.ksvesity__funfact-container_items-four p{
    width: 112px;
    height: 24px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2E425C;
    margin-top: -1.7rem;

}
@media screen and (max-width:1350px) {
    .ksvesity__funfact-container{
        margin-top: 7rem;
        margin-bottom: 7rem;
    }
}
@media screen and (max-width:1200px) {
    .ksvesity__funfact-container_items{
        display: grid;
        grid-template-columns: 1fr 0.6fr;
        grid-gap:30px
    }
}
@media screen and (max-width:650px) {
    .ksvesity__funfact-container_items-one h1,.ksvesity__funfact-container_items-two h1,.ksvesity__funfact-container_items-three h1,.ksvesity__funfact-container_items-four h1{
        font-size: 35px;
        /* margin-left: 1rem; */
    }
    .ksvesity__funfact-container_items-one p,.ksvesity__funfact-container_items-two p,.ksvesity__funfact-container_items-three p,.ksvesity__funfact-container_items-four p{
        margin-right: 2.5rem;
    }
    .ksvesity__funfact-container_items{
        display: grid;
        grid-template-columns: 1fr 0.6fr;
    }
}
@media screen and (max-width:500px) {
    .ksvesity__funfact-container_items-one h1,.ksvesity__funfact-container_items-two h1,.ksvesity__funfact-container_items-three h1,.ksvesity__funfact-container_items-four h1{
        font-size: 30px;
    }
    .ksvesity__funfact-container_items-one p,.ksvesity__funfact-container_items-two p,.ksvesity__funfact-container_items-three p,.ksvesity__funfact-container_items-four p{
        font-size: 13px;
    }
    .ksvesity__funfact-container_items{
        display: grid;
        grid-template-columns: 145px 145px;
    }
    .ksvesity__funfact-container{
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}
@media screen and (max-width:415px) {
    .ksvesity__funfact-container_items{
        display: grid;
        grid-template-columns: 1fr;
    }

    .ksvesity__funfact-container_items-one h1,.ksvesity__funfact-container_items-two h1,.ksvesity__funfact-container_items-three h1,.ksvesity__funfact-container_items-four h1{
        font-size: 35px;
        margin-left: 4.5rem;

    }
    .ksvesity__funfact-container_items-one p, .ksvesity__funfact-container_items-two p,.ksvesity__funfact-container_items-three p, .ksvesity__funfact-container_items-four p{
        width: 120x;
        height: 24px;
        font-size: 12px;
        margin-left: 1rem;
    }
}
