.ksvesity__faq{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ksvesity__faq-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: 4rem auto 4rem auto;

}
.ksvesity__faq-container-div{
    border-bottom: 1px solid grey;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ksvesity__faq-container-div_item-faq-p_active{
    display: block;
}
.ksvesity__faq-container-div_item-faq-p_active span{
    color: blue;
    cursor: pointer;
}
.ksvesity__faq-container h1{
    /* margin-top: -1rem; */
    width: 89px;
    height: 43px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 35.2px;
    line-height: 42px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0F64D2;
    margin-bottom: 3rem;
}

.ksvesity__faq-container-div_item-faq-p{
    display: none;
}
.ksvesity__faq-container-div_item-faq h3{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.16px;
    margin-top: 0.7rem;

}
@media screen and (max-width:850px) {
    .ksvesity__faq-container-div_item-faq h3{
        width: 280px;
    }
    .ksvesity__faq-container-div_item-faq p{
        width: 450.04px
    }
    .ksvesity__faq-container-div{
        width: 500px;
    }

}
@media screen and (max-width:650px) {
    .ksvesity__faq-container-div_item-faq h3{
        width: 280px;
    }
    .ksvesity__faq-container-div_item-faq p{
        width: 360.04px
    }
    .ksvesity__faq-container-div{
        width: 400px;
    }

}
@media screen and (max-width:415px) {
    .ksvesity__faq-container-div_item-faq h3{
        width: 280px;
        font-size: 15px;
    }
    .ksvesity__faq-container-div_item-faq p{
        width: 340px;
        font-size: 14px;
        
    }
    .ksvesity__faq-container-div{
        width: 370px;
    }
}
@media screen and (max-width:393px) {
    .ksvesity__faq-container-div_item-faq h3{
        width: 250px;
        font-size: 13px;
    }
    .ksvesity__faq-container-div_item-faq p{
        width: 270px;
        font-size: 12px;
        
    }
    .ksvesity__faq-container-div{
        width: 320px;
    }
}
