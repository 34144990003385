.ksvesity__contactusenroll{
    display: flex;
    padding: 100px;

}
.ksvesity__contactusenroll-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.ksvesity__contactusenroll-container h2{
    width: 525px;
    font-family: Nunito Sans;
    font-size: 35px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;
    color: #2E425C;
}
.ksvesity__contactusenroll-container button{
    width: Hug (141.36px);
    height: Hug (51.19px);
    padding: 15px 29.5625px 16.1875px 27.796875px;
    border-radius: 4.800000190734863px;
    box-shadow: 0px 7.199999809265137px 14.399999618530273px 0px #55857C4D;
    background: #0F64D2;
    margin-top: 2rem;
    border: none;
    color:white
}
@media screen and (max-width:550px) {
    .ksvesity__contactusenroll-container h2{
        width: 350px;
        font-size: 30px;
    }
}